import "styles/pages/about.scss"

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

const About = ({ data }) => {
  const title = data.wpPage.title

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="about">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-lg-5">
              <PageSubHeader title="Historia" />
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.wpPage?.acfAbout?.aboutHistory,
                }}
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <PageSubHeader title="Memtech w liczbach" />
              <div className="row about__row">
                {data?.wpPage?.acfAbout?.aboutNumbers.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <div className="about__row-inner">
                      <img
                        src={item.aboutNumberIcon?.localFile?.publicURL}
                        alt=""
                      />
                      <p>
                        <span>{item.aboutNumberValue}</span>{" "}
                        {item.aboutNumberDescription}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyNTgy" }) {
      title
      acfAbout {
        aboutHistory
        aboutNumbers {
          aboutNumberIcon {
            sourceUrl
            localFile {
              publicURL
            }
          }
          aboutNumberValue
          aboutNumberDescription
        }
      }
    }
  }
`

export default About
